import axios from 'axios'
import Vue from 'vue'

export const baseURL = 'https://server.yeneproperty.com/api'
// export const baseURL = 'http://192.168.8.180:1078/api'
// export const baseURL = 'http://localhost:1078/api'

const axiosIns = axios.create({
  baseURL,
  headers: {
    isWeb: true,
    localdate: new Date(Date.now()),
    currentpropertydata: {},
  },
  withCredentials: true,
  timeout: 20 * 1000,
})

Vue.prototype.$http = axiosIns

export default axiosIns
