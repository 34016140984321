import {
  Ajax, commonMutations, getApiData, getApiTotal, isApiSuccess, LogicStateStatus, RouteConst,
} from '@/libs/ajax'

const stateM = {
  payments: [],
  total: 0,
  logicStateStatus: LogicStateStatus.none,
}

const mutations = {
  UPDATE_PAYMENTS(state, payload) {
    state.payments = payload
  },
  UPDATE_PAYMENTS_INFO(state, payload) {
    const oldPayments = state.payments
    const paymentIndex = oldPayments.indexOf(oldPayments.find(element => element.id === payload.id))
    if (paymentIndex !== -1) {
      oldPayments[paymentIndex] = payload
      state.payments = oldPayments
    }
  },
  UPDATE_TOTAL(state, payload) {
    state.total = payload
  },
  DELETE_PAYMENTS(state, payload) {
    const oldPayments = state.payments
    const index = oldPayments.indexOf(oldPayments.find(element => element.id === payload.id))
    if (index !== -1
    ) {
      oldPayments.splice(index, 1)
      state.payments = oldPayments
    }
  },
  [commonMutations.CHANGE_LOGIC_STATUS](state, payload) {
    state.logicStateStatus = payload
  },
}

const actions = {
  // eslint-disable-next-line no-unused-vars
  async getPaymentHistory({ commit, state }, payload) {
    commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.loading)
    let payments = []
    try {
      const body = {
        roomId: payload.roomId,
        page: payload.page,
      }
      const res = await Ajax(`${RouteConst.history}/getPaymentHistory`, { body })
      const data = getApiData(res)
      if (isApiSuccess(res)) {
        commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.success)
        payments = data
        commit('UPDATE_PAYMENTS', payments)
        if (payload.page === 1) {
          commit('UPDATE_TOTAL', getApiTotal(res))
        }
        // if (payments.length > 0) {
        //   if (state.payments.length <= 0 || payload.page === 1) {
        //     commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.success)
        //   } else {
        //     const oldPayments = state.payments
        //     oldPayments.push(...payments)
        //     commit('UPDATE_PAYMENTS', oldPayments)
        //     commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.success)
        //   }
        // } else {
        //   commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.loadNoData)
        // }
        // if (payments.length < 13) {
        //   commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.loadNoData)
        // }
      }
    } catch (error) {
      payload.notify('Error', error.message)
      commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.fail)
    }
  },

}

export default {
  namespaced: true,
  state: stateM,
  mutations,
  actions,
}
