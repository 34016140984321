/* eslint-disable class-methods-use-this */

// const UserTypeRole = {
//   admin: '0',
//   supervisor: '1',
//   onlyView: '2',
// }

const allowed = {
  // home
  report: '1',
  addProperty: '2',
  editProperty: '2.1',
}

export default class UserAllowed {
  isReportAllowed(v) {
    return v.includes(allowed.report)
  }

  isAddPropertyAllowed(v) {
    return v.includes(allowed.addProperty)
  }

  isEditPropertyAllowed(v) {
    return v.includes(allowed.editProperty)
  }
}
