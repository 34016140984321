import {
  Ajax, commonMutations, getApiData, isApiSuccess, LogicStateStatus, RouteConst,
} from '@/libs/ajax'

const stateM = {
  roomModel: {},
  logicStateStatus: LogicStateStatus.none,
}

const mutations = {
  CHANGE_DATA(state, payload) {
    state.roomModel = payload
  },
  [commonMutations.CHANGE_LOGIC_STATUS](state, payload) {
    state.logicStateStatus = payload
  },
}

const actions = {
  async addRoom({ commit, rootState }, payload) {
    commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.loading)
    try {
      const body = {
        model: payload.roomModel,
        propId: rootState.currentProperty.propertyModel.id,
      }
      const res = await Ajax(`${RouteConst.rooms}/addRoom`, { body })
      if (isApiSuccess(res)) {
        // const data = getApiData(res)
        commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.success)
      }
    } catch (error) {
      payload.notify('Error', error.message)
      commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.fail)
    }
  },
  async updateRoom({ commit }, payload) {
    commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.loading)
    try {
      const body = {
        model: payload.roomModel,
      }
      const res = await Ajax(`${RouteConst.rooms}/updateRoom`, { body })
      if (isApiSuccess(res)) {
        const data = getApiData(res)
        commit('rooms/UPDATE_ROOM_INFO', data, { root: true })
        commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.success)
      }
    } catch (error) {
      payload.notify('Error', error.message)
      commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.fail)
    }
  },
}

export default {
  namespaced: true,
  state: stateM,
  mutations,
  actions,
}
