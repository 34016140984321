const errorMessageKey = {
  noCompanyCode: '2201',
  incorrectPassword: '2202',
  notEnoughUnit: '2203',
  anAuthorizedCode: '2204',
  noSetupForTwoFactorAuthCode: '2205',
  paymentsLoggedThatAreAfterNewEndDate: '2206',
}

export default errorMessageKey
