import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import Vue from 'vue'
import VueExcelEditor from 'vue-excel-editor'
import { Cascader } from 'element-ui'

// eslint-disable-next-line import/extensions
import NProgressContainer from 'vue-nprogress/src/NprogressContainer'

Vue.component(Cascader.name, Cascader)

Vue.use(VueExcelEditor)
Vue.component('nprogress-container', NProgressContainer)

Vue.component(FeatherIcon.name, FeatherIcon)
