// Modules
import SecureLS from 'secure-ls'
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import app from './app'
import appConfig from './app-config'
import moduleAuth from './auth/moduleAuth'
import moduleAddEditExpense from './expense/moduleAddEditExpense'
import moduleExpenses from './expense/moduleExpenses'
import moduleHome from './home/moduleHome'
import moduleAddEditProperty from './moduleAddEditProperty'
import moduleCurrentProperty from './moduleCurrentProperty'
import moduleAddEditReminder from './reminder/moduleAddEditReminder'
import moduleReminders from './reminder/moduleReminders'
import moduleAddEditLease from './roomAction/moduleAddEditLease'
import moduleAddEditPayment from './roomAction/moduleAddEditPayment'
import modulePayments from './roomAction/modulePayments'
import moduleAddEditRoom from './rooms/moduleAddEditRoom'
import moduleGenerateRooms from './rooms/moduleGenerateRooms'
import moduleRooms from './rooms/moduleRooms'
import moduleAddEditTenant from './tenants/moduleAddEditTenant'
import moduleTenants from './tenants/moduleTenants'
import verticalMenu from './vertical-menu'
import moduleReportPropertySelected from './reportPropertySelected/moduleReportPropertySelected'
import moduleReport from './report/moduleReport'

const ls = new SecureLS({ isCompression: false })

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth: moduleAuth,
    home: moduleHome,
    // property
    addEditProperty: moduleAddEditProperty,
    currentProperty: moduleCurrentProperty,
    // expense
    expenses: moduleExpenses,
    addEditExpense: moduleAddEditExpense,
    // reminder
    addEditReminder: moduleAddEditReminder,
    reminders: moduleReminders,
    // tenant
    tenants: moduleTenants,
    addEditTenant: moduleAddEditTenant,
    // rooms
    rooms: moduleRooms,
    addEditRoom: moduleAddEditRoom,
    generateRooms: moduleGenerateRooms,

    // payments
    payments: modulePayments,
    addEditPayment: moduleAddEditPayment,

    // lease
    addEditLease: moduleAddEditLease,

    // report
    reportPropertySelected: moduleReportPropertySelected,
    report: moduleReport,
  },
  plugins: [
    createPersistedState({
      paths: ['auth.AppActiveUser', 'auth.companyInfo', 'currentProperty'],
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key),
      },
    }),
  ],
  strict: process.env.DEV,
})
