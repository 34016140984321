import { commonModelField } from '@/api/field_model'
import {
  Ajax, commonMutations, getApiData, isApiSuccess, LogicStateStatus, RouteConst,
} from '@/libs/ajax'

const stateM = {
  logicStateStatus: LogicStateStatus.none,
}

const mutations = {
  [commonMutations.CHANGE_LOGIC_STATUS](state, payload) {
    state.logicStateStatus = payload
  },
}

const actions = {
  async addNewLease({ commit }, payload) {
    commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.loading)
    try {
      const body = {
        model: payload.leaseModel,
        logPreviousPayments: payload.logPreviousPayments,
        previousLeaseId: payload.previousLeaseId,
        terminatePreviousLease: payload.previousLeaseId !== null,
      }
      const res = await Ajax(`${RouteConst.leases}/addNewLease`, { body })
      if (isApiSuccess(res)) {
        const data = getApiData(res)
        commit('rooms/UPDATE_ROOM_INFO', data, { root: true })
        commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.success)
      }
    } catch (error) {
      payload.notify('Error', error.message)
      commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.fail)
    }
  },
  async updateLease({ commit }, payload) {
    commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.loading)
    try {
      const body = {
        model: payload.leaseModel,
        hasPaymentMadeBefore: payload.hasPaymentMadeBefore,
        lastLeaseEnd: payload.lastLeaseEnd,
      }
      const res = await Ajax(`${RouteConst.leases}/updateLease`, { body })
      if (isApiSuccess(res)) {
        const data = getApiData(res)
        commit('rooms/UPDATE_ROOM_INFO', data, { root: true })
        commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.success)
      }
    } catch (error) {
      payload.notify('Error', error.message)
      commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.fail)
    }
  },
  async deleteLease({ commit }, payload) {
    commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.loading)
    try {
      const body = {
        model: payload.leaseModel,
      }
      const res = await Ajax(`${RouteConst.leases}/deleteLease`, { body })
      if (isApiSuccess(res)) {
        const data = getApiData(res)
        commit('rooms/UPDATE_ROOM_INFO', data, { root: true })
        commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.success)
      }
    } catch (error) {
      payload.notify('Error', error.message)
      commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.fail)
    }
  },
  async terminateLease({ commit }, payload) {
    commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.loading)
    try {
      const body = {
        [commonModelField.leaseId]: payload.leaseId,
        [commonModelField.roomId]: payload.id,
      }
      const res = await Ajax(`${RouteConst.leases}/terminateAndMakeAsUntenanted`, { body })
      if (isApiSuccess(res)) {
        const data = getApiData(res)
        commit('rooms/UPDATE_ROOM_INFO', data, { root: true })
        commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.success)
      }
    } catch (error) {
      payload.notify('Error', error.message)
      commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.fail)
    }
  },
}

export default {
  namespaced: true,
  state: stateM,
  mutations,
  actions,
}
