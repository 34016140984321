import { propertiesModelField } from '@/api/field_model'
import {
  Ajax, commonMutations, getApiData, isApiSuccess, LogicStateStatus, RouteConst,
} from '@/libs/ajax'

const stateM = {
  propertyModel: {},
  logicStateStatus: LogicStateStatus.none,
}

const mutations = {
  CHANGE_DATA(state, payload) {
    state.propertyModel = payload
  },
  [commonMutations.CHANGE_LOGIC_STATUS](state, payload) {
    state.logicStateStatus = payload
  },
}

const actions = {
  async updateProperty({ commit }, payload) {
    commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.loading)
    try {
      const res = await Ajax(`${RouteConst.properties}/${payload.propertyModel.id}`)
      if (isApiSuccess(res)) {
        const data = getApiData(res)
        commit('home/UPDATE_STATE', data.properties[0], { root: true })
        commit('home/UPDATE_HOME_DATA', data.properties.homeDataModel, { root: true })
        commit('CHANGE_DATA', data.properties.homeDataModel)
        commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.success)
      }
    } catch (error) {
      payload.notify('Error', error.message)
      commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.fail)
    }
  },
}

const getters = {
  numberOfFloors: state => state.propertyModel[propertiesModelField.numberOfFloors],
  numberOfGroundNo: state => state.propertyModel.numberOfGroundNo,
  numberOfBasement: state => state.propertyModel[propertiesModelField.numberOfBasement],
}

export default {
  namespaced: true,
  state: stateM,
  mutations,
  actions,
  getters,
}
