import {
  Ajax, commonMutations, getApiData, isApiSuccess, LogicStateStatus, RouteConst,
} from '@/libs/ajax'

const stateM = {
  logicStateStatus: LogicStateStatus.none,
}

const mutations = {
  [commonMutations.CHANGE_LOGIC_STATUS](state, payload) {
    state.logicStateStatus = payload
  },
}

const actions = {
  async addTenant({ commit }, payload) {
    commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.loading)
    try {
      const body = {
        model: payload.tenantModel,
      }
      const res = await Ajax(`${RouteConst.tenants}/addTenant`, { body })
      if (isApiSuccess(res)) {
        const data = getApiData(res)
        commit('tenants/ADD_TENANT', data, { root: true })
        commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.success)
      }
    } catch (error) {
      payload.notify('Error', error.message)
      commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.fail)
    }
  },
  async updateTenant({ commit }, payload) {
    commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.loading)
    try {
      const body = {
        model: payload.tenantModel,
      }
      const res = await Ajax(`${RouteConst.tenants}/updateTenant`, { body })
      if (isApiSuccess(res)) {
        // const data = getApiData(res)
        commit('tenants/UPDATE_TENANTS_INFO', payload.tenantModel, { root: true })
        commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.success)
      }
    } catch (error) {
      payload.notify('Error', error.message)
      commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.fail)
    }
  },
  async deleteTenant({ commit }, payload) {
    commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.loading)
    try {
      const body = {
        id: payload.tenantModel.id,
      }
      const res = await Ajax(`${RouteConst.tenants}/deleteTenant`, { body })
      if (isApiSuccess(res)) {
        // const data = getApiData(res)
        commit('tenants/DELETE_TENANT', payload.tenantModel, { root: true })
        commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.success)
      }
    } catch (error) {
      payload.notify('Error', error.message)
      commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.fail)
    }
  },
}

const getters = {}

export default {
  namespaced: true,
  state: stateM,
  mutations,
  actions,
  getters,
}
