import {
  Ajax, commonMutations, getApiData, isApiSuccess, LogicStateStatus, RouteConst,
} from '@/libs/ajax'

const defaultState = {
  logicStateStatus: LogicStateStatus.none,
  totalNumberOfUnits: 0,
  rooms: [],
  roomsApiParameter: {
    propId: null,
    floorNo: null,
    basementNo: null,
    isGround: false,
    page: 1,
    query: '',
    jumpNo: null,
    filter: {
      overDue: false,
      dueOn: false,
      unrented: false,
      leaseEnd: false,
    },
  },

}

const stateM = { ...defaultState }

const mutations = {
  [commonMutations.CHANGE_LOGIC_STATUS](state, payload) {
    state.logicStateStatus = payload
  },
  UPDATE_ROOMS(state, payload) {
    state.rooms = [...payload]
  },
  UPDATE_TOTAL(state, payload) {
    state.totalNumberOfUnits = payload
  },
  CHANGE_ROOM_API_PARAMETER(state, payload) {
    state.roomsApiParameter = { ...payload }
  },
  // eslint-disable-next-line no-unused-vars
  RESET_STATE(state, payload) {
    // console.log(defaultState)
    const roomsApiParameter = {
      propId: null,
      floorNo: null,
      basementNo: null,
      isGround: false,
      page: 1,
      query: '',
      jumpNo: null,
      filter: {
        overDue: false,
        dueOn: false,
        unrented: false,
        leaseEnd: false,
      },
    }
    state.logicStateStatus = LogicStateStatus.none
    state.totalNumberOfUnits = 0
    state.rooms = []

    state.roomsApiParameter = { ...roomsApiParameter }
  },
  // eslint-disable-next-line no-unused-vars
  RESET_FILTER(state, payload) {
    // state.roomsApiParameter.filter = { ...defaultState.roomsApiParameter.filter }
    state.roomsApiParameter.filter.overDue = false
    state.roomsApiParameter.filter.dueOn = false
    state.roomsApiParameter.filter.unrented = false
    state.roomsApiParameter.filter.leaseEnd = false
  },
  // eslint-disable-next-line no-unused-vars
  UPDATE_PAGE(state, payload) {
    state.roomsApiParameter.page += 1
  },
  UPDATE_ROOM_INFO(state, payload) {
    const oldRooms = state.rooms
    const roomIndex = oldRooms.indexOf(oldRooms.find(element => element.id === payload.id))
    if (roomIndex !== -1) {
      oldRooms[roomIndex] = payload
      state.rooms = oldRooms
    }
  },
}

const actions = {
  async getRooms({ commit, state, rootState }, payload) {
    commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.loading)
    try {
      let rooms = []

      const body = { ...state.roomsApiParameter }
      body.propId = rootState.currentProperty.propertyModel.id

      const res = await Ajax(`${RouteConst.rooms}/getRooms`, { body })
      if (isApiSuccess(res)) {
        const data = getApiData(res)
        rooms = data.rooms
        if (state.rooms.length <= 0 || state.roomsApiParameter.page === 1) {
          commit('UPDATE_ROOMS', rooms)
          commit('UPDATE_TOTAL', getApiData(res).totalNumberOfUnits)
          commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.success)
        } else {
          const oldRooms = state.rooms
          oldRooms.push(...rooms)
          commit('UPDATE_ROOMS', oldRooms)
          commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.success)
        }
        commit('UPDATE_PAGE')
        if (rooms.length < 12 || state.rooms.length === state.totalNumberOfUnits) {
          commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.loadNoData)
        }
      }
    } catch (error) {
      payload.notify('Error', error.message)
      commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.fail)
    }
  },
  async searchRoom({ commit, state, rootState }, payload) {
    commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.loading)
    try {
      let rooms = []

      const body = { ...state.roomsApiParameter }
      body.propId = rootState.currentProperty.propertyModel.id

      const res = await Ajax(`${RouteConst.rooms}/searchRoom`, { body })
      if (isApiSuccess(res)) {
        const data = getApiData(res)
        rooms = data.rooms
        if (state.rooms.length <= 0 || state.roomsApiParameter.page === 1) {
          commit('UPDATE_ROOMS', rooms)
          commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.success)
        } else {
          const oldRooms = state.rooms
          oldRooms.push(...rooms)
          commit('UPDATE_ROOMS', oldRooms)
          commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.success)
        }
        // if (rooms.length < 13) {
        //   commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.loadNoData)
        // }
      }
    } catch (error) {
      payload.notify('Error', error.message)
      commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.fail)
    }
  },
}

const getters = {
  totalNumberOfUnits: state => state.totalNumberOfUnits,
}

export default {
  namespaced: true,
  state: stateM,
  mutations,
  actions,
  getters,
}
