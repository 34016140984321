import {
  Ajax, commonMutations, getApiData, getApiTotal, isApiSuccess, LogicStateStatus, RouteConst,
} from '@/libs/ajax'

// eslint-disable-next-line no-extend-native,func-names
Array.prototype.insert = function (index, item) {
  this.splice(index, 0, item)
}

const stateM = {
  tenants: [],
  total: 0,
  logicStateStatus: LogicStateStatus.none,
}

const mutations = {
  [commonMutations.CHANGE_LOGIC_STATUS](state, payload) {
    state.logicStateStatus = payload
  },
  UPDATE_TENANTS(state, payload) {
    state.tenants = payload
  },
  ADD_TENANT(state, payload) {
    state.tenants.insert(0, payload)
  },
  UPDATE_TOTAL(state, payload) {
    state.total = payload
  },
  UPDATE_TENANTS_INFO(state, payload) {
    const oldTenants = state.tenants
    const tenantIndex = oldTenants.indexOf(oldTenants.find(element => element.id === payload.id))
    if (tenantIndex !== -1) {
      oldTenants[tenantIndex] = payload
      state.tenants = oldTenants
    }
  },
  DELETE_TENANT(state, payload) {
    const oldTenants = state.tenants
    const index = oldTenants.indexOf(oldTenants.find(element => element.id === payload.id))
    if (index !== -1) {
      oldTenants.splice(index, 1)
      state.tenants = oldTenants
    }
  },
}

const actions = {
  async getTenants({ commit }, payload) {
    commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.loading)
    try {
      const body = {
        page: payload.page,
        roomId: payload.roomId,
        propId: payload.propId,
      }
      const res = await Ajax(`${RouteConst.tenants}/getTenants`, { body })
      if (isApiSuccess(res)) {
        const data = getApiData(res)
        commit('UPDATE_TENANTS', data)
        if (getApiTotal(res) && getApiTotal(res) > 0) {
          commit('UPDATE_TOTAL', getApiTotal(res))
        }
        commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.success)
      }
    } catch (error) {
      payload.notify('Error', error.message)
      commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.fail)
    }
  },
}

const getters = {}

export default {
  namespaced: true,
  state: stateM,
  mutations,
  actions,
  getters,
}
