/* eslint-disable max-classes-per-file */
import { isDef } from '@/assets/helpers'

const companiesModelField = {
  name: 'nm',
  companyDomain: 'cD',
  logo: 'lo',
  password: 'pwd',
  trialExpireDay: 'tED',
  hasSetupComplete: 'hSC',
  numberOfUnitAllowed: 'nOUA',
  twoFactorVerification: 'tFV',
  phoneNumber: 'pN',
  isNotPaid: 'iNP',
  isTemporaryAccount: 'iTA',
}

const hasSetupComplete = {
  yes: '0',
  no: '1',
}

const userRoleType = {
  admin: '0',
  supervisor: '1',
  onlyView: '2',
}

const usersModelField = {
  // compId
  username: 'un',
  role: 'ro',
  password: 'pwd',
  allowed: 'alw',
  allowedPropertyId: 'aPI',
  token: 'tok',
  lastLogin: 'lL',
}

const propertiesType = {
  building: 'b',
  individualUnit: 'iU',
}

const propertiesModelField = {
  // compId
  name: 'nm',
  country: 'ctr',
  city: 'ci',
  location: 'loc',
  mainImage: 'mI',
  type: 'tp', // propertiesType
  otherImage: 'oI',
  numberOfFloors: 'nOF',
  numberOfBasement: 'nOB',
  leaseExpiryReminderDaysBefore: 'lERDB',
  tenantRentReminderDaysBefore: 'tRRDB',
  tenantOverdueReminderDaysAfter: 'tORDA',
  checkOutTime: 'cOT',
}

const roomsPaymentFrequency = {
  oneTime: 'oT',
  weekly: 'w',
  monthly: 'm',
  yearly: 'y',
}

const roomStatus = {
  unrented: '1',
}

const leaseModelField = {
  // roomId
  // tenantId
  startDate: 'sD',
  leaseEnd: 'leasE', // contract
  paymentFrequency: 'pF', // roomsPaymentFrequency
  paymentFrequencyAmount: 'pFA', // 1, 2(week, month, year)
  contractImages: 'cI',
  rentAmount: 'rA',
  dateType: 'dT',
}

const roomModelField = {
  // propId
  // leaseId
  no: 'no',
  status: 'st',
  customName: 'cN',
  floorNo: 'fN', // if 0 treat it as ground
  basementNo: 'bN',
  numberOfBeds: 'nOBe',
  numberOfBaths: 'nOBt',
  area: 'ar',
  images: 'imgs',
  nextPayout: 'nP',
  depositAmount: 'depA',
}

const remindersModelField = {
  // compId
  // propId
  dateAndTime: 'dAT', // 20 Aug 2021 05:00 PM
  category: 'cat', // Gas safety check
  notes: 'notes',
  isCompleted: 'isC',
}

const tenantModelField = {
  // roomId
  // compId
  nationIdFrontImage: 'nIFI',
  nationIdBackImage: 'nIBI',
  phone: 'ph',
  fullName: 'fN',
  email: 'em',
  otherFiles: 'oFi',
}

const roomHistoriesType = {
  history: 'h',
  payment: 'p',
  deposit: 'd',
  // damage: 'dm',
  serviceFee: 'sF',
}

const roomHistoriesAction = {
  leave: 'l',
  enter: 'e',
  paymentNonModified: 'pNM',
  paymentComplete: 'pC',
  paymentIncomplete: 'pI',
}

const roomHistoriesModelField = {
  // roomId
  // leaseId
  // tenantId
  // compId
  type: 'tp', // history, payment (roomHistoriesType)
  amount: 'am',
  paymentDate: 'pD',
  img: 'img',
  action: 'ac', // leave, enter ... (roomHistoriesAction)
}

const expenseModelField = {
  // propId
  // roomId
  amount: 'am',
  dueDate: 'dD',
  repeatInterval: 'rI',
  repeatIntervalAmount: 'rIA',
  repeatEndDate: 'rED',
  category: 'cat',
  note: 'note',
  image: 'img',
}

const reportTypeField = {
  incomeExpenseStatement: '1', // per room, including expense
  profitLossSummary: '2', // per room, including expense
  overdueRentPayments: '3',
  leasesExpiring: '4',
  tenantDirectory: '5',
  untenantedUnits: '6',
}

const commonModelField = {
  id: 'id',
  tenantId: 'tenantId',
  compId: 'compId',
  propId: 'propId',
  roomId: 'roomId',
  leaseId: 'leaseId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
}

class ExpenseCategory {
  constructor() {
    this.Accountant = '1'
    this.Advertising = '2'
    this.Agent = '3'
    this.Bank = '4'
    this.Cleaning = '5'
    this.Ground = '6'
    this.Insurance = '7'
    this.Late = '8'
    this.Legal = '9'
    this.Maintenance = '10'
    this.Property = '11'
    this.Rates = '12'
    this.Rent = '13'
    this.Utilities = '14'
    this.Water = '15'
    this.Other = '16'
  }

  getLabel(e) {
    let r = ''
    switch (e) {
      case this.Accountant:
        r = 'Accountant\'s Fees'
        break
      case this.Advertising:
        r = 'Advertising'
        break
      case this.Agent:
        r = 'Agent Fees'
        break
      case this.Bank:
        r = 'Bank Fees'
        break
      case this.Cleaning:
        r = 'Cleaning Fees'
        break
      case this.Ground:
        r = 'Ground Rents'
        break
      case this.Insurance:
        r = 'Insurance'
        break
      case this.Late:
        r = 'Late Fees'
        break
      case this.Legal:
        r = 'Legal Fees'
        break
      case this.Maintenance:
        r = 'Maintenance & Repairs'
        break

      case this.Property:
        r = 'Property Tax'
        break
      case this.Rates:
        r = 'Rates'
        break
      case this.Rent:
        r = 'Rent Arrears'
        break
      case this.Utilities:
        r = 'Utilities'
        break
      case this.Water:
        r = 'Water'
        break
      case this.Other:
        r = 'Other'
        break
      default:
    }
    return r
  }

  expenseCategories() {
    return [...Array(16).keys()].map(v => ({ value: `${v + 1}`, title: this.getLabel(`${v + 1}`) }))
  }
}

class ReminderCategory {
  constructor() {
    this.Electrical = '1'
    this.Gas = '2'
    this.Insurance = '3'
    this.Property = '4'
    this.Other = '5'
  }

  getLabel(e) {
    let r = ''
    switch (e) {
      case this.Electrical:
        r = 'Electrical Safety Check'
        break
      case this.Gas:
        r = 'Gas Safety Check'
        break
      case this.Insurance:
        r = 'Insurance Policy Renewal'
        break
      case this.Property:
        r = 'Property Inspection'
        break
      case this.Other:
        r = 'Other'
        break
      default:
    }
    return r
  }

  reminderCategories() {
    return [...Array(5).keys()].map(v => ({ value: `${v + 1}`, title: this.getLabel(`${v + 1}`) }))
  }
}

class PropertiesType {
  constructor() {
    this.building = 'b'
    this.individualUnit = 'iU'
  }

  getLabel(e) {
    let r = ''
    switch (e) {
      case this.building:
        r = 'Building complex'
        break
      case this.individualUnit:
        r = 'Separate Units'
        break
      default:
    }
    return r
  }

  // reminderCategories() {
  //   return [...Array(5).keys()].map(v => ({ value: `${v + 1}`, title: this.getLabel(`${v + 1}`) }))
  // }
}

class RoomsLabel {
  constructor() {
    this.oneTime = 'oT'
    this.weekly = 'w'
    this.monthly = 'm'
    this.yearly = 'y'
  }

  getPaymentFrequencyLabel(v) {
    let r = ''
    switch (v) {
      case this.oneTime:
        r = 'One Time'
        break
      case this.weekly:
        r = 'Weekly'
        break
      case this.monthly:
        r = 'Monthly'
        break
      case this.yearly:
        r = 'Yearly'
        break
      default:
    }
    return r
  }

  paymentFrequencyList() {
    return [
      {
        title: this.getPaymentFrequencyLabel(this.oneTime),
        value: this.oneTime,
      },
      {
        title: this.getPaymentFrequencyLabel(this.monthly),
        value: this.monthly,
      },
      {
        title: this.getPaymentFrequencyLabel(this.weekly),
        value: this.weekly,
      },
      {
        title: this.getPaymentFrequencyLabel(this.yearly),
        value: this.yearly,
      },
    ]
  }

  // eslint-disable-next-line class-methods-use-this
  getRoomNoLabel(roomModel, { no, ignoreCustomName = false }) {
    return `${(ignoreCustomName ? false : roomModel[roomModelField.customName].length > 0)
      ? roomModel[roomModelField.customName]
      : `${(isDef(roomModel[roomModelField.floorNo])
        ? roomModel[roomModelField.floorNo]
        : roomModel[roomModelField.basementNo])}${(no || roomModel[roomModelField.no]) >= 10 ? '' : 0}${(no || roomModel[roomModelField.no])}`
    }`
  }
}

class HistoryLabel {
  constructor() {
    // type
    this.history = 'h'
    this.payment = 'p'
    this.serviceFee = 'sF'
    this.deposit = 'd'

    // action
    this.leave = 'l'
    this.enter = 'e'
    this.paymentNonModified = 'pNM'
    this.paymentComplete = 'pC'
    this.paymentIncomplete = 'pI'
  }

  paymentTypes() {
    return [
      {
        title: this.getHistoryTypeLabel(this.payment),
        value: this.payment,
      },
      {
        title: this.getHistoryTypeLabel(this.deposit),
        value: this.deposit,
      },
      {
        title: this.getHistoryTypeLabel(this.serviceFee),
        value: this.serviceFee,
      },
    ]
  }

  paymentActions() {
    return [
      {
        title: this.getActionLabel(this.paymentComplete),
        value: this.paymentComplete,
      },
      {
        title: this.getActionLabel(this.paymentIncomplete),
        value: this.paymentIncomplete,
      },

    ]
  }

  getHistoryTypeLabel(v) {
    let r = ''
    switch (v) {
      case this.payment:
        r = 'Rent'
        break
      case this.serviceFee:
        r = 'Service Fee'
        break
      case this.deposit:
        r = 'Deposit'
        break
      default:
    }
    return r
  }

  getActionLabel(e) {
    let r = ''
    switch (e) {
      case this.paymentComplete:
        r = 'Payment complete'
        break
      case this.paymentIncomplete:
        r = 'Payment Incomplete'
        break

      default:
        break
    }
    return r
  }
}

const getReportLabel = e => {
  let r = ''
  switch (e) {
    case reportTypeField.incomeExpenseStatement:
      r = 'Income Expense Statement'
      break
    case reportTypeField.profitLossSummary:
      r = 'Profit Loss Summary'
      break
    case reportTypeField.overdueRentPayments:
      r = 'Overdue Rent Payments'
      break
    case reportTypeField.leasesExpiring:
      r = 'Leases Expiring'
      break
    case reportTypeField.tenantDirectory:
      r = 'Tenant Directory'
      break
    case reportTypeField.untenantedUnits:
      r = 'Unrented Units'
      break

    default:
      break
  }
  return r
}

const dateLocalTypeField = {
  normal: 1,
  ethiopian: 2,
}

export {
  propertiesModelField,
  companiesModelField,
  roomModelField,
  propertiesType,
  remindersModelField,
  roomHistoriesModelField,
  tenantModelField,
  roomsPaymentFrequency,
  roomHistoriesType,
  roomHistoriesAction,
  userRoleType,
  usersModelField,
  hasSetupComplete,
  roomStatus,
  expenseModelField,
  leaseModelField,
  reportTypeField,
  ExpenseCategory,
  ReminderCategory,
  PropertiesType,
  RoomsLabel,
  commonModelField,
  HistoryLabel,
  getReportLabel,
  dateLocalTypeField,
}
