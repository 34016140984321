/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import {
  Ajax, commonMutations, getApiData, isApiSuccess, LogicStateStatus, RouteConst,
} from '@/libs/ajax'

const stateM = {
  logicStateStatus: LogicStateStatus.none,
}

const getters = {
  isLoading: state => state.logicStateStatus === LogicStateStatus.loading,
}

const mutations = {
  [commonMutations.CHANGE_LOGIC_STATUS](state, payload) {
    state.logicStateStatus = payload
  },
}

const actions = {
  async addProperties({ commit, dispatch }, payload) {
    commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.loading)
    try {
      const res = await Ajax(`${RouteConst.properties}/addProperties`, {
        body: {
          propertiesModel: payload.propertyModel,
        },
      })
      if (isApiSuccess(res)) {
        commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.success)

        // eslint-disable-next-line no-param-reassign
        payload.page = 1
        dispatch('home/getMyProperties', payload, { root: true })
      }
    } catch (error) {
      payload.notify('Error', error.message)
      commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.fail)
    }
  },
  async updateProperty({ commit, dispatch }, payload) {
    commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.loading)
    try {
      const res = await Ajax(`${RouteConst.properties}/updateProperty`, {
        body: {
          propertiesModel: payload.propertyModel,
        },
      })
      if (isApiSuccess(res)) {
        commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.success)
        commit('currentProperty/CHANGE_DATA', payload.propertyModel, { root: true })
        commit('home/UPDATE_STATE', payload.propertyModel, { root: true })
        if (payload.updateProperty) {
          dispatch('currentlyProperty/updateProperty', payload.propertyModel, { root: true })
        }
      }
    } catch (error) {
      payload.notify('Error', error.message)
      commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.fail)
    }
  },
}

export default {
  namespaced: true,
  state: stateM,
  mutations,
  actions,
  getters,
}
