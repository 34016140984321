/* eslint-disable */
const mwUnique = {
  prevTimeId: 0,
  prevUniqueId: 0,
  getUniqueID: function() {
    try {
      const d = new Date()
      let newUniqueId = d.getTime()
      if (newUniqueId == mwUnique.prevTimeId) mwUnique.prevUniqueId += 1
      else {
        mwUnique.prevTimeId = newUniqueId
        mwUnique.prevUniqueId = 0
      }
      newUniqueId = `${newUniqueId}${mwUnique.prevUniqueId}`
      return newUniqueId
    } catch (e) {
      mwTool.logError(`mwUnique.getUniqueID error:${e.message}.`)
    }
  },
}


const debounce = (func, delay = 200) => {
  let timer = null

  // eslint-disable-next-line func-names
  return function (...args) {
    // eslint-disable-next-line no-unused-expressions
    timer && clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, args)
    }, delay)
  }
}

const isDef = (v) => v !== null && v !== undefined && v !== 'null'
const isDefAndNotEmpty = (v) => isDef(v) && v.length > 0
const parseDate =(v) => new Date(v).toISOString().substr(0, 19).replace('T', ' ')

const paginateArray = (array, perPage, page) => array.slice((page - 1) * perPage, page * perPage)

const noImage = `https://res.cloudinary.com/zuber/image/upload/v1595665732/oneShop/constant/noimage.jpg`;
const kFakeNumber = -9999;

export { mwUnique, debounce, isDefAndNotEmpty, isDef, parseDate, noImage, kFakeNumber, paginateArray }

