/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import {
  Ajax, getApiData, RouteConst, isApiSuccess,
} from '@/libs/ajax'
import router from '@/router'
import { companiesModelField, dateLocalTypeField } from '@/api/field_model'
import errorMessageKey from '@/api/errorMessageKeys'

const state = {
  isUserLoggedIn: () => true,
  AppActiveUser: null,
  companyInfo: null,
  // let isAuthenticated = false

  // // get firebase current user
  // const firebaseCurrentUser = firebase.auth().currentUser

  // if (auth.isAuthenticated() || firebaseCurrentUser) isAuthenticated = true
  // else isAuthenticated = false

  // return localStorage.getItem('userInfo') && isAuthenticated
}

const mutations = {
  UPDATE_USER_INFO(state, payload) {
    // const isAdmin = (payload.role || '') === UserTypeRole.admin
    // payload.isAdmin = isAdmin
    // payload.salesBranchId = isDef(payload.salesBranchId) ? payload.salesBranchId : kFakeNumber
    state.AppActiveUser = payload
  },
  UPDATE_COMPANY_INFO(state, payload) {
    state.companyInfo = { ...payload, dateLocalType: dateLocalTypeField.normal }
  },
  // eslint-disable-next-line no-unused-vars
  UPDATE_DATE_LOCAL_TYPE(state, payload) {
    state.companyInfo.dateLocalType = payload
  },
  // eslint-disable-next-line no-unused-vars
  LOGOUT_USER(state, payload) {
    state.AppActiveUser = null
  },
  // eslint-disable-next-line no-unused-vars
  LOGOUT_COMPANY(state, payload) {
    state.companyInfo = null
  },
}

const actions = {
  logoutUser({ commit }, payload) {
    commit('LOGOUT_USER', payload)
    router.push('/LoginByPass')
  },
  logoutCompany({ commit }, payload) {
    commit('LOGOUT_COMPANY', payload)
    commit('LOGOUT_USER', payload)
    router.push('/login')
  },
  async loginByUser({ commit }, payload) {
    const res = await Ajax(`${RouteConst.users}/loginWeb`, {
      body: payload,
    })

    const data = getApiData(res)
    if (data.isPasswordCo === true) {
      localStorage.setItem('loginAttemptTryTimes', 0)
      commit('UPDATE_USER_INFO', data.userInfo)
      router.push('/')
    } else {
      throw Error('Incorrect password')
    }
  },
  async loginByCompany({ commit }, payload) {
    try {
      const res = await Ajax(`${RouteConst.companies}/loginCompany`, {
        body: {
          companyModel: payload,
          [companiesModelField.companyDomain]: payload[companiesModelField.companyDomain],
          [companiesModelField.password]: payload[companiesModelField.password],
        },
      })
      const data = getApiData(res)
      if (isApiSuccess(res)) {
        commit('UPDATE_COMPANY_INFO', data)
        router.push('/LoginByPass')
      } else if (res.msg === errorMessageKey.noCompanyCode) {
        payload.notify('Company Not registered', 'No company has registered with the given company name please try again or contact us if your company has not been registered.')
      } else if (res.msg === errorMessageKey.incorrectPassword) {
        payload.notify('Error', 'Incorrect company password')
      } else if (res.msg === 'NOT_ALLOWED_FOR_DESKTOP') {
        payload.notify('Error', 'Your Company is not allowed to login from web please contact us to continue.')
      } else {
        payload.notify('Error', res.msg)
      }
    } catch (err) {
      payload.notify('Error', err.message)
    }
  },
}

const getters = {
  dateLocalType: state => state.companyInfo.dateLocalType,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
