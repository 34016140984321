// import Zemen from 'zemen'
import {
  expenseModelField, propertiesModelField, propertiesType, remindersModelField, roomModelField, tenantModelField, roomHistoriesModelField, roomHistoriesType, roomHistoriesAction, commonModelField, HistoryLabel, leaseModelField,
} from '@/api/field_model'
import { kFakeNumber } from '@/assets/helpers'

const defaultPropertyModel = {
  id: kFakeNumber,
  [propertiesModelField.mainImage]: '',
  [propertiesModelField.name]: '',
  [propertiesModelField.type]: {
    title: 'Building complex',
    value: propertiesType.building,
  },
  [propertiesModelField.otherImage]: [],
  [propertiesModelField.numberOfBasement]: 0,
  [propertiesModelField.numberOfFloors]: 0,
  [propertiesModelField.checkOutTime]: new Date(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate(), 12, 0, 0),
  [propertiesModelField.leaseExpiryReminderDaysBefore]: 30,
  [propertiesModelField.tenantRentReminderDaysBefore]: 5,
  [propertiesModelField.tenantOverdueReminderDaysAfter]: 1,
}

const defaultExpenseModel = {
  id: kFakeNumber,
  propId: kFakeNumber,
  roomId: kFakeNumber,
  [expenseModelField.amount]: 0,
  [expenseModelField.dueDate]: new Date(),
}

const defaultReminderModel = {
  id: kFakeNumber,
  propId: kFakeNumber,
  [remindersModelField.dateAndTime]: new Date(),
}
const defaultTenantModel = {
  id: kFakeNumber,
  [tenantModelField.otherFiles]: [],
  [tenantModelField.fullName]: '',
}

const defaultRoomModel = {
  id: kFakeNumber,
  [roomModelField.no]: 0,
  [roomModelField.customName]: '',
  [roomModelField.floorNo]: 0,
  [roomModelField.basementNo]: 0,
  [roomModelField.numberOfBeds]: 0,
  [roomModelField.numberOfBaths]: 0,
  [roomModelField.area]: '',
  [roomModelField.images]: [],
  paymentPeriod: [],
}

const defaultHistoryModel = {
  [commonModelField.id]: kFakeNumber,
  [roomHistoriesModelField.type]: {
    title: new HistoryLabel().getHistoryTypeLabel(roomHistoriesType.payment),
    value: roomHistoriesType.payment,
  },
  [roomHistoriesModelField.action]: {
    title: new HistoryLabel().getActionLabel(roomHistoriesAction.paymentComplete),
    value: roomHistoriesAction.paymentComplete,
  },
  [commonModelField.tenantId]: kFakeNumber,
  [commonModelField.leaseId]: kFakeNumber,
  [commonModelField.roomId]: kFakeNumber,
}

const defaultLeaseModel = {
  [commonModelField.id]: kFakeNumber,
  tenant: { ...defaultTenantModel },
  [commonModelField.roomId]: kFakeNumber,
  [leaseModelField.contractImages]: [],
  [leaseModelField.startDate]: new Date(Date.now()),
  [leaseModelField.leaseEnd]: new Date(Date.now()),
  // [leaseModelField.paymentFrequency]: {
  //   value: roomsPaymentFrequency.monthly,
  //   title: new RoomsLabel().getPaymentFrequencyLabel(roomsPaymentFrequency.monthly),
  // },
  // [leaseModelField.paymentFrequencyAmount]: {
  //   value: '1',
  //   title: '1',
  // },
  [leaseModelField.paymentFrequency]: null,
  [leaseModelField.paymentFrequencyAmount]: null,
  [leaseModelField.rentAmount]: 0,
}

export {
  defaultPropertyModel,
  defaultExpenseModel,
  defaultReminderModel,
  defaultTenantModel,
  defaultRoomModel,
  defaultHistoryModel,
  defaultLeaseModel,
}
