import { reportTypeField } from '@/api/field_model'
import {
  Ajax, commonMutations, getApiData, isApiSuccess, LogicStateStatus, RouteConst,
} from '@/libs/ajax'

const stateM = {
  logicStateStatus: LogicStateStatus.none,
  incomeExpenseStatementReportModel: {
    rentReports: [],
    propertyExpenses: [],
  },
  overdueRentPaymentsReportModel: [],
  untenantedUnitsReportModel: [],
}

const mutations = {
  [commonMutations.CHANGE_LOGIC_STATUS](state, payload) {
    state.logicStateStatus = payload
  },
  SET_INCOME_EXPENSE_STATEMENT_REPORT_STATE(state, payload) {
    state.incomeExpenseStatementReportModel = payload
  },
  SET_OVERDUE_RENT_PAYMENTS_REPORT_STATE(state, payload) {
    state.overdueRentPaymentsReportModel = payload
  },
  SET_UNTENANTED_UNITS_REPORT_STATE(state, payload) {
    state.untenantedUnitsReportModel = payload
  },
  // eslint-disable-next-line no-unused-vars
  RESET_STATE(state, payload) {
    state.incomeExpenseStatementReportModel = {
      rentReports: [],
      propertyExpenses: [],
    }
    state.untenantedUnitsReportModel = []
    state.overdueRentPaymentsReportModel = []
  },
}

const actions = {
  async getReport({ commit }, payload) {
    commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.loading)
    const { reportParameter } = payload
    const body = reportParameter
    try {
      const res = await Ajax(`${RouteConst.reports}/getReport`, { body })
      if (isApiSuccess(res)) {
        commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.success)
        const data = getApiData(res)
        if (reportParameter.reportType
          === reportTypeField.incomeExpenseStatement
 || reportParameter.reportType === reportTypeField.profitLossSummary) {
          const incomeExpenseStatementReportModel = {
            rentReports: [],
            propertyExpenses: [],
          }
          incomeExpenseStatementReportModel.rentReports = data.rentReports
          incomeExpenseStatementReportModel.propertyExpenses = data.propertyExpenses
          commit('SET_INCOME_EXPENSE_STATEMENT_REPORT_STATE', incomeExpenseStatementReportModel)
        } else if (reportParameter.reportType === reportTypeField.overdueRentPayments
        || reportParameter.reportType === reportTypeField.tenantDirectory
                  || reportParameter.reportType === reportTypeField.leasesExpiring) {
          commit('SET_OVERDUE_RENT_PAYMENTS_REPORT_STATE', data)
        } else if (reportParameter.reportType
                    === reportTypeField.untenantedUnits) {
          commit('SET_UNTENANTED_UNITS_REPORT_STATE', data)
        }
      }
    } catch (error) {
      payload.notify('Error', error.message)
      commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.fail)
    }
  },

}

const getters = {
  isReportLoading: state => state.logicStateStatus === LogicStateStatus.loading,
}

export default {
  namespaced: true,
  state: stateM,
  mutations,
  actions,
  getters,
}
