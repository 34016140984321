import {
  Ajax, commonMutations, getApiData, getApiTotal, isApiSuccess, LogicStateStatus, RouteConst,
} from '@/libs/ajax'

// eslint-disable-next-line no-extend-native,func-names
Array.prototype.insert = function (index, item) {
  this.splice(index, 0, item)
}

const stateM = {
  reminders: [],
  total: 0,
  logicStateStatus: LogicStateStatus.none,
}

const mutations = {
  [commonMutations.CHANGE_LOGIC_STATUS](state, payload) {
    state.logicStateStatus = payload
  },
  UPDATE_REMINDERS(state, payload) {
    state.reminders = payload
  },
  ADD_REMINDER(state, payload) {
    state.reminders.insert(0, payload)
  },
  UPDATE_TOTAL(state, payload) {
    state.total = payload
  },
  UPDATE_REMINDERS_INFO(state, payload) {
    const oldReminders = state.reminders
    const reminderIndex = oldReminders.indexOf(oldReminders.find(element => element.id === payload.id))
    if (reminderIndex !== -1) {
      oldReminders[reminderIndex] = payload
      state.reminders = oldReminders
    }
  },
  DELETE_REMINDER(state, payload) {
    const oldReminders = state.reminders
    const index = oldReminders.indexOf(oldReminders.find(element => element.id === payload.id))
    if (index !== -1) {
      oldReminders.splice(index, 1)
      state.reminders = oldReminders
    }
  },
}

const actions = {
  async getReminders({ commit }, payload) {
    commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.loading)
    try {
      const body = {
        page: payload.page,
        roomId: payload.roomId,
        propId: payload.propId,
      }
      const res = await Ajax(`${RouteConst.reminders}/getReminders`, { body })
      if (isApiSuccess(res)) {
        const data = getApiData(res)
        commit('UPDATE_REMINDERS', data)
        if (getApiTotal(res) && getApiTotal(res) > 0) {
          commit('UPDATE_TOTAL', getApiTotal(res))
        }
        commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.success)
      }
    } catch (error) {
      payload.notify('Error', error.message)
      commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.fail)
    }
  },
}

const getters = {}

export default {
  namespaced: true,
  state: stateM,
  mutations,
  actions,
  getters,
}
