import {
  Ajax, commonMutations, getApiData, isApiSuccess, LogicStateStatus, RouteConst,
} from '@/libs/ajax'

const stateM = {
  reportPropertySelectedList: [],
  logicStateStatus: LogicStateStatus.none,
}

const mutations = {
  SET_STATE(state, payload) {
    state.reportPropertySelectedList = payload
  },
  [commonMutations.CHANGE_LOGIC_STATUS](state, payload) {
    state.logicStateStatus = payload
  },
  CHANGE_DATA(state, propertySelectedModel) {
    const oldReport = state.reportPropertySelectedList
    const roomIndex = oldReport.findIndex(element => element.propertyListModel.propertyModel.id === propertySelectedModel.propertyListModel.propertyModel.id)
    if (roomIndex !== -1) {
      oldReport[roomIndex] = propertySelectedModel
      state.reportPropertySelectedList = oldReport
    }
  },
}

const actions = {
  // eslint-disable-next-line no-unused-vars
  async getPropertyParameters({ commit }, payload) {
    commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.loading)
    try {
      let propertyLists = []
      const res = await Ajax(`${RouteConst.reports}/getAllPropertyParameter`, {})
      if (isApiSuccess(res)) {
        const data = getApiData(res)
        propertyLists = data.map(v => (
          {
            propertyListModel: {
              propertyModel: v,
              rooms: v.rooms,
            },
            isSelected: false,
            floorIsSelected: false,
            groundIsSelected: false,
            basementIsSelected: false,
            selectedFloors: [],
            selectedBasements: [],
            selectedRoomsIds: [],
          }
        ))
        commit('SET_STATE', propertyLists)
        commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.success)
      }
    } catch (error) {
      payload.notify('Error', error.message)
      commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.fail)
    }
  },
}

const getters = {
  isLoading: state => state.logicStateStatus === LogicStateStatus.loading,
  totalNumberOfRoomsSelected(state) {
    let selected = 0
    for (let index = 0; index < state.reportPropertySelectedList.length; index += 1) {
      const item = state.reportPropertySelectedList[index]
      selected += item.selectedRoomsIds.length
    }
    return selected
  },
  selectedRoomIds(state) {
    const roomIds = []
    for (let index = 0; index < state.reportPropertySelectedList.length; index += 1) {
      const item = state.reportPropertySelectedList[index]
      roomIds.push(...item.selectedRoomsIds)
    }
    return roomIds
  },
  allPropertyIds(state) {
    const propIds = []
    for (let index = 0; index < state.reportPropertySelectedList.length; index += 1) {
      const item = state.reportPropertySelectedList[index]
      propIds.push(item.propertyListModel.propertyModel.id)
    }
    return propIds
  },
  selectedPropertyIds(state) {
    const propIds = []
    for (let index = 0; index < state.reportPropertySelectedList.length; index += 1) {
      const item = state.reportPropertySelectedList[index]
      if (item.isSelected) {
        propIds.push(item.propertyListModel.propertyModel.id)
      }
    }
    return propIds
  },
}

export default {
  namespaced: true,
  state: stateM,
  mutations,
  actions,
  getters,
}
