/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import { usersModelField } from '@/api/field_model'
import {
  Ajax, commonMutations, getApiData, isApiSuccess, LogicStateStatus, RouteConst,
} from '@/libs/ajax'

const stateM = {
  properties: [],
  homeDataModel: {},
  logicStateStatus: LogicStateStatus.none,
}

const getters = {
  totalMoneyOutstandingInThisMonth: state => (state.homeDataModel.propertyRevenue ? state.homeDataModel.propertyRevenue.totalMoneyOutstandingInThisMonth : 0),
  totalMoneyCollectedInThisMonth: state => (state.homeDataModel.propertyRevenue ? state.homeDataModel.propertyRevenue.totalMoneyCollectedInThisMonth : 0),
  totalMoneyOutstandingInPreviousMonth: state => (state.homeDataModel.propertyRevenue ? state.homeDataModel.propertyRevenue.totalMoneyOutstandingInPreviousMonth : 0),
  totalMoneyCollectedInPreviousMonth: state => (state.homeDataModel.propertyRevenue ? state.homeDataModel.propertyRevenue.totalMoneyCollectedInPreviousMonth : 0),
}

const mutations = {
  [commonMutations.CHANGE_LOGIC_STATUS](state, payload) {
    state.logicStateStatus = payload
  },
  UPDATE_PROPERTIES(state, payload) {
    state.properties = payload
  },
  UPDATE_STATE(state, payload) {
    const oldProperties = state.properties
    const propertyIndex = oldProperties.indexOf(oldProperties.find(element => element.id === payload.id))
    if (propertyIndex !== -1) {
      oldProperties[propertyIndex] = payload
      state.properties = oldProperties
    }
  },
  UPDATE_HOME_DATA(state, payload) {
    state.homeDataModel = payload
  },
}

const actions = {
  async getMyProperties({ commit, state, rootState }, payload) {
    commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.loading)
    const r = {
      properties: [],
      homeDataModel: {},
    }
    try {
      const res = await Ajax(`${RouteConst.properties}/getMyProperties`, {
        body: {
          page: payload.page,
          userId: rootState.auth.AppActiveUser.id,
          allowedPropId: rootState.auth.AppActiveUser[usersModelField.allowedPropertyId],
          // 'version': packageInfo.version,
          // 'token': AuthLocalData().getFCMToken(),
        },
      })
      const data = getApiData(res)
      if (isApiSuccess(res)) {
        r.properties = data.properties
        r.homeDataModel = {
          thisMonthRevenue: data.homeData ? data.homeData.thisMonthRevenue : '0',
          thisMonthExpense: data.homeData ? data.homeData.thisMonthExpense : '0',
          propertyRevenue: data.propertyRevenue ? data.propertyRevenue : null,
          dailyTipsList: data.dailyTipsList,
        }
        if (r.properties.length > 0) {
          if (state.properties.length <= 0 || payload.page === 1) {
            commit('UPDATE_PROPERTIES', r.properties)
            commit('UPDATE_HOME_DATA', r.homeDataModel)
            commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.success)
          } else {
            const oldProperties = state.properties
            oldProperties.push(r.properties)
            commit('UPDATE_PROPERTIES', oldProperties)
            commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.success)
          }
        } else {
          commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.loadNoData)
        }
        if (r.properties.length < 12) {
          commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.loadNoData)
        }
      }
    } catch (e) {
      console.error(e)
      payload.notify('Error', e.message)
      commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.fail)
    }
  },
}

export default {
  namespaced: true,
  state: stateM,
  mutations,
  getters,
  actions,
}
