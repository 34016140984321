import UserAllowed from '@/auth/userAllowed'

const FULL = 'full'

export default [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: FULL,
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: FULL,
      // resource: 'Auth',
      // redirectIfLoggedIn: true,
    },
  },
  {
    path: '/LoginByPass',
    name: 'login-by-pass',
    component: () => import('@/views/LoginByPass.vue'),
    meta: {
      layout: FULL,
      // resource: 'Auth',
      // redirectIfLoggedIn: true,
    },
  },
  {
    path: '/setting',
    name: 'setting',
    component: () => import('@/views/GeneralSetting/GeneralSettingPage.vue'),
  },
  {
    path: '/property',
    name: 'property',
    meta: {
      pageTitle: 'Property',
      breadcrumb: [
        // {
        //   text: 'Home',
        //   to: '/',
        // },
        {
          text: 'Property',
          active: true,
        },
      ],
      navActiveLink: 'property',
    },
    component: () => import('@/views/Property/PropertyPage.vue'),
  },
  {
    path: '/rooms',
    name: 'rooms',
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      // pageTitle: 'Rooms',
      // breadcrumb: [
      //   {
      //     text: 'Property',
      //     to: '/property',
      //   },
      //   {
      //     text: 'Rooms',
      //     active: true,
      //   },
      // ],
      // navActiveLink: 'rooms',
    },
    component: () => import('@/views/Rooms/RoomsPage.vue'),
  },
  {
    path: '/not-authorized',
    name: 'not-authorized',
    component: () => import('@/views/error/NotAuthorized.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/report',
    name: 'report',
    meta: {
      auth: new UserAllowed().isReportAllowed,
    },
    component: () => import('@/views/ReportFilter/FilterReportPage.vue'),
  },
  {
    path: '/filter_room',
    name: 'filter_room',
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
    },
    component: () => import('@/views/OverdueAndLeaseEndAndUnrentedRoomsPage/OverdueAndLeaseEndAndUnrentedRoomsPage.vue'),
  },

]
