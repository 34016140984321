import { companiesModelField } from '@/api/field_model'
import {
  Ajax, commonMutations, getApiData, isApiSuccess, LogicStateStatus, RouteConst,
} from '@/libs/ajax'
import errorMessageKey from '@/api/errorMessageKeys'

const stateM = {
  logicStateStatus: LogicStateStatus.none,
}

const mutations = {

  [commonMutations.CHANGE_LOGIC_STATUS](state, payload) {
    state.logicStateStatus = payload
  },
}

const actions = {
  async generateRoom({ commit, rootState }, payload) {
    commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.loading)
    try {
      const body = {
        propId: rootState.currentProperty.propertyModel.id,
        floorNo: rootState.rooms.roomsApiParameter.floorNo,
        basementNo: rootState.rooms.roomsApiParameter.basementNo,
        isGround: rootState.rooms.roomsApiParameter.isGround,
        numberOfUnit: payload.numberOfUnit,
        update: payload.update,
        numberOfUnitAllowed: rootState.auth.companyInfo[companiesModelField.numberOfUnitAllowed],
      }
      const res = await Ajax(`${RouteConst.rooms}/generateRoom`, { body })
      if (isApiSuccess(res)) {
        const data = getApiData(res)
        commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.success)
        if (res.msg === errorMessageKey.notEnoughUnit) {
          payload.swal({
            position: 'center',
            icon: 'warning',
            title: 'Not enough unit',
            text: `You are asking ${data.asked} units, you are only allowed ${rootState.auth.companyInfo[companiesModelField.numberOfUnitAllowed]} units, you have used ${data.used} units, remaining ${data.currentlyHave} units`,
            showCancelButton: false,
            confirmButtonText: 'OK',
            customClass: {
              confirmButton: 'btn btn-secondary',
            },
            showClass: {
              popup: 'animate__animated animate__shakeX',
            },
            buttonsStyling: false,
          })
        } else {
          commit('rooms/UPDATE_ROOMS', data.rooms, { root: true })
          commit('rooms/UPDATE_TOTAL', getApiData(res).totalNumberOfUnits, { root: true })
        }
      }
    } catch (error) {
      payload.notify('Error', error.message)
      commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.fail)
    }
  },
}

export default {
  namespaced: true,
  state: stateM,
  mutations,
  actions,
}
