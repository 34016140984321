import SecureLS from 'secure-ls'
import { defaultPropertyModel } from '@/api/defaultModel'
import axiosIns, { baseURL } from '@/libs/axios'
import router from '@/router'

const ls = new SecureLS({ isCompression: false })

// eslint-disable-next-line no-unused-vars
export const Ajax = async (url, { body = {}, isAdmin = false }) => {
  const URL = `${baseURL}${url}`
  let headers = null
  const state = ls.get('vuex') ? JSON.parse(ls.get('vuex')) : { currentProperty: { propertyModel: { ...defaultPropertyModel } } }
  if (state.currentProperty && state.currentProperty.propertyModel) {
    headers = { currentpropertydata: JSON.stringify(state.currentProperty.propertyModel) }
  }
  const response = await axiosIns.post(URL, body, headers ? {
    headers,
  } : null)
  // console.log(response && response.data)
  if (response && response.data && response.data.code === '--1') {
    return router.push('/login')
  }
  return response ? response.data : null
}

export const RouteConst = {
  companies: '/companies',
  users: '/users',
  properties: '/properties',
  expenses: '/expenses',
  reminders: '/reminders',
  tenants: '/tenants',
  rooms: '/rooms',
  history: '/history',
  leases: '/leases',
  reports: '/reports',
}

export const isApiSuccess = req => req && (req.code === '0' || req.code === 0)
export const getApiData = req => req.data
export const getApiTotal = req => req.total
export const getWebData = req => req.webData

export const LogicStateStatus = {
  loading: 'loading',
  loadingFirst: 'loadingFirst',
  success: 'success',
  fail: 'fail',
  none: 'none',
  loadNoData: 'loadNoData',
  deleteData: 'deleteData',
}

export const commonMutations = {
  CHANGE_LOGIC_STATUS: 'CHANGE_LOGIC_STATUS',
}
