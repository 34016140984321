import {
  Ajax, commonMutations, getApiData, isApiSuccess, LogicStateStatus, RouteConst,
} from '@/libs/ajax'

const stateM = {
  logicStateStatus: LogicStateStatus.none,
}

const mutations = {

  [commonMutations.CHANGE_LOGIC_STATUS](state, payload) {
    state.logicStateStatus = payload
  },
}

const actions = {
  async addExpense({ commit }, payload) {
    commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.loading)
    try {
      const body = {
        model: payload.expenseModel,
      }
      const res = await Ajax(`${RouteConst.expenses}/addExpense`, { body })
      if (isApiSuccess(res)) {
        const data = getApiData(res)
        commit('expenses/ADD_EXPENSES', data, { root: true })
        commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.success)
      }
    } catch (error) {
      payload.notify('Error', error.message)
      commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.fail)
    }
  },
  async updateExpense({ commit }, payload) {
    commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.loading)
    try {
      const body = {
        model: payload.expenseModel,
      }
      const res = await Ajax(`${RouteConst.expenses}/updateExpense`, { body })
      if (isApiSuccess(res)) {
        // const data = getApiData(res)
        commit('expenses/UPDATE_EXPENSES_INFO', payload.expenseModel, { root: true })
        commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.success)
      }
    } catch (error) {
      payload.notify('Error', error.message)
      commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.fail)
    }
  },
  async deleteExpense({ commit }, payload) {
    commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.loading)
    try {
      const body = {
        id: payload.expenseModel.id,
      }
      const res = await Ajax(`${RouteConst.expenses}/deleteExpense`, { body })
      if (isApiSuccess(res)) {
        // const data = getApiData(res)
        commit('expenses/DELETE_EXPENSE', payload.expenseModel, { root: true })
        commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.success)
      }
    } catch (error) {
      payload.notify('Error', error.message)
      commit(commonMutations.CHANGE_LOGIC_STATUS, LogicStateStatus.fail)
    }
  },
}

const getters = {}

export default {
  namespaced: true,
  state: stateM,
  mutations,
  actions,
  getters,
}
